import { createContext, useEffect, useState } from 'react'
import { FirebaseApp, initializeApp } from 'firebase/app';

type Props = {children: React.ReactNode}

export const FirebaseContext = createContext<
  { app: null | FirebaseApp }
>({
  app: null,
});

export const FirebaseProvider = ({ children }: Props) => {
  const [app, setApp] = useState<null | FirebaseApp>(null);

  useEffect(() => {
    const firebaseConfig = {
        apiKey: "AIzaSyDsChNhrsuepuj_1NK7d9vQNVtlOtrayog",
        authDomain: "fork-your-wiki.firebaseapp.com",
        projectId: "fork-your-wiki",
        storageBucket: "fork-your-wiki.appspot.com",
        messagingSenderId: "881634514876",
        appId: "1:881634514876:web:88f4eec1c823b6d0027db9",
        measurementId: "G-KF0EL6YDM6"
      };

      setApp(initializeApp(firebaseConfig));
  }, []);

  return (
    <FirebaseContext.Provider value={{ app }}>
      {app ? children : null}
    </FirebaseContext.Provider>
  )
}