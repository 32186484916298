import { useCallback, useState } from 'react';
import { TextField } from '../../components/textField/TextField';
import { Button } from '../../components/button/Button';
import { sendEmail } from './helpers/sendEmail';
import { LoadingOverlay } from '../../components/loadingOverlay/LoadingOverlay';
import { FeedbackMessage } from './types/FeedbackMessage';

import './contactForm.css';

interface Props {
    variant?: 'small' | 'regular'
}

export const ContactForm = ({ variant = 'regular' }: Props) => {
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [phoneNumber, setPhoneNumber] = useState('');
    const [message, setMessage] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [feedbackMessage, setFeedbackMessage] = useState<FeedbackMessage | null>(null);

    const handleSubmitClick = useCallback(() => {
        setIsLoading(true);
        setFeedbackMessage(null);
        sendEmail(name, email, phoneNumber, message)
            .then(() => {
                setIsLoading(false);
                setFeedbackMessage({
                    type: 'info',
                    message: 'Message sent'
                });
                setName('');
                setEmail('');
                setPhoneNumber('');
                setMessage('');
            })
            .catch(() => {
                setIsLoading(false);
                setFeedbackMessage({
                    type: 'error',
                    message: 'Something went wrong. Please try again.'
                })
            });
    }, [name, email, phoneNumber, message]);

    return (
        <div className={`ContactForm ContactForm--${variant}`}>
            <div className="ContactForm__Field">
                <TextField label="Name" placeholder="Enter name" onChange={setName} value={name} variant={variant} />
            </div>
            <div className="ContactForm__Field">
                <TextField label="Email Address" type="email" placeholder="Enter email address" onChange={setEmail} value={email} variant={variant} />
            </div>
            <div className="ContactForm__Field">
                <TextField label="Contact Number" placeholder="Enter contact number" onChange={setPhoneNumber} value={phoneNumber} variant={variant} />
            </div>
            <div className="ContactForm__Field">
                <TextField label="Message" type="textarea" placeholder="Enter your message..." onChange={setMessage} value={message} variant={variant} />
            </div>
            <div className="ContactForm__Actions">
                {feedbackMessage && (
                    <div className={`ContactForm__FeedbackMessage ContactForm__FeedbackMessage--${feedbackMessage.type}`}>
                        {feedbackMessage.message}
                    </div>
                )}
                <Button onClick={handleSubmitClick}>
                    Submit
                </Button>
            </div>
            {isLoading && <LoadingOverlay />}
        </div>
    );
};

