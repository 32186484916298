import { useContext, useEffect } from "react";

import { Hero } from "../../components/hero/Hero";
import { HeroContent } from "./components/HeroContent";
import { CardGrid } from "./components/CardGrid";
import { CaseStudies } from "./components/CaseStudies";
import { MetaContext } from "../../contexts/MetaContext";

import heroImage from '../../assets/images/our-wiki-pages-hero.png';

import './landingPage.css';

export const LandingPage = () => {
    const { reset } = useContext(MetaContext);

    useEffect(() => {
        reset();
    }, [reset]);

    return (
        <div>
            <Hero img={heroImage} alt="Migration">
                <HeroContent />
            </Hero>
            <CardGrid />
            <div className="LandingPage__CaseStudies">
                <div className="LandingPage__CaseStudiesHeader">
                    <div className="LandingPage__CaseStudiesTitle">Case Studies</div>
                </div>
                <CaseStudies />
            </div>
        </div>
    )
};