export const sendEmail = async (name: string, email: string, phoneNumber: string, message: string) => {
    const response = await fetch("https://us-central1-fork-your-wiki.cloudfunctions.net/contact", {
        method: "POST",
        body: JSON.stringify({
            name,
            email,
            phoneNumber,
            message,
        }),
        headers: {
          "Content-type": "application/json; charset=UTF-8"
        }
      });

    if (!response.ok) {
        throw new Error('Request failed');
    }

    return;
};
