import { useContext, useEffect, useState } from "react";
import { getPolicy } from "./helpers/getPolicy";
import { FirebaseContext } from "../../contexts/FirebaseContext";
import { PolicyEntry } from "../../types/PolicyEntry";
import Markdown from "react-markdown";
import { LoadingOverlay } from "../../components/loadingOverlay/LoadingOverlay";

import './policyDisplay.css';

interface Props {
    policyName: string
}

export const PolicyDisplay = ({ policyName }: Props) => {
    const { app } = useContext(FirebaseContext);
    const [entries, setEntries] = useState<PolicyEntry[]>([]);
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState('');

    useEffect(() => {
        if (app == null) return;
        setIsLoading(true);
        getPolicy(app, policyName)
            .then((result) => {
                setIsLoading(false);
                setEntries(result);
            })
            .catch(() => {
                setIsLoading(false);
                setError('Failed To Load policy document. Please reload.')
            })
    }, [app, policyName]);

    const renderEntry = (entry: PolicyEntry) => {
        switch (entry.type) {
            case 'heading':
                return <div key={entry.text} className="PolicyDisplay__Header">{entry.text}</div>
            case 'paragraph':
                return <Markdown key={entry.text} className="PolicyDisplay__Paragraph">{entry.text}</Markdown>
        }
    }
    return (
        <div className="PolicyDisplay">
            {isLoading && <LoadingOverlay />}
            <div className="PolicyDisplay__Content">
                {error !== '' && <div className="PolicyDisplay__Error">{error}</div>}
                {entries.map((entry) => renderEntry(entry))}
            </div>
        </div>
    )
};
