import { useContext, useEffect, useState } from "react"
import { TransitionCard } from "../../../components/tranisitonCard/TransitionCard"
import { CaseStudy } from "../../../types/CaseStudy"
import { getCaseStudy } from "../../../helpers/getCaseStudy"
import { FirebaseContext } from "../../../contexts/FirebaseContext"

import './caseStudyCard.css';
import { Link } from "react-router-dom"

interface Props {
    caseStudyId: string
}

export const CaseStudyCard = ({ caseStudyId }: Props) => {
    const { app } = useContext(FirebaseContext);
    const [caseStudy, setCaseStudy] = useState<CaseStudy | null>(null);

    useEffect(() => {
        if (app == null) return;
        getCaseStudy(app, caseStudyId)
            .then((result) => {
                setCaseStudy(result);
            });
    }, [app, caseStudyId]);

    if (caseStudy == null) return null;

    return (
        <Link to={`/case-study/${caseStudy.id}`}>
            <TransitionCard>
                <div className="CaseStudyCard">
                    {caseStudy.logo && <img className="CaseStudyCard__Image" src={caseStudy.logo} alt={caseStudy.title} />}
                    <div className="CaseStudyCard__Title">{caseStudy.title}</div>
                    <div className="CaseStudyCard__Description">{caseStudy.description}</div>
                </div>
            </TransitionCard>
        </Link>
    )
}