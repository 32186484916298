import { Link } from 'react-router-dom';
import './heroContent.css';

export const HeroContent = () => (
    <div className="HeroContent">
        <div className="HeroContent__Title">Empowering Independence</div>
        <div className="HeroContent__Subtitle">
            Our mission is to empower wiki communities to gain independence from platforms that don't value their users. We offer custom hosting solutions, and expert guidance, while drawing on our past foundational success.
        </div>
        <Link className="HeroContent__Button" to="/contact">
            GET IN TOUCH
        </Link>
    </div>
);
