import { Copy } from "../../../types/Copy"

import './caseStudyCopy.css';

interface Props {
    copy: Copy
}

export const CaseStudyCopy = ({ copy }: Props) => {
    const renderContent = () => {
        switch (copy.type) {
            case 'image':
                return <img className="CaseStudyCopy__Image" src={copy.src} alt="Copy for case study" />
            case 'text':
                let className = 'CaseStudyCopy__Text';
                if (copy.bold) {
                    className += ' CaseStudyCopy__Text--bold'
                }
                return (
                    <div className={className}>
                        {copy.text}
                    </div>
                );
            default:
                throw new Error('Invalid copy type');
        }
    }

    return (
        <div className="CaseStudyCopy">
            {renderContent()}
        </div>
    )
}