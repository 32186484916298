import { ReactNode } from 'react';
import './button.css';

interface Props {
    children: ReactNode
    onClick: () => void
}

export const Button = ({ children, onClick }: Props) => {
    return (
        <button className="Button" onClick={onClick}>
            {children}
        </button>
    )
};
