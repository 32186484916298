import { ReactNode } from 'react';

import './cardContent.css';

interface Props {
    title: string
    message: string
    children: ReactNode
}

export const CardContent = ({ title, message, children }: Props) => {
    return (
        <div className="CardContent">
            <div>
                <div className="CardContent__Title">{title}</div>
                <div className="CardContent__Message">
                    {message}
                </div>
            </div>
            <div className="CardContent__Image">
                {children}
            </div>
        </div>
    )
};
