import Lottie from "react-lottie";

interface Props {
    data: any
}

export const Animation = ({ data }: Props) => (
    <Lottie
        options={{
            animationData: data,
            autoplay: true,
            loop: true,
        }}
        width="100%"
    />
);