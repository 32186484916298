import { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { CaseStudy } from "../../types/CaseStudy";
import { getCaseStudy } from "../../helpers/getCaseStudy";
import { FirebaseContext } from "../../contexts/FirebaseContext";
import { CaseStudyCopy } from "./components/CaseStudyCopy";
import { getCopyKey } from "./helpers/getCopyKey";
import { MetaContext } from "../../contexts/MetaContext";
import { CaseStudyCard } from "./components/CaseStudyCard";

import './caseStudyPage.css';
import { ContactForm } from "../../components/contactForm/ContactForm";

export const CaseStudyPage = () => {
    const { id } = useParams();
    const { app } = useContext(FirebaseContext);
    const { setTitle, setDescription } = useContext(MetaContext);
    const [caseStudy, setCaseStudy] = useState<CaseStudy | null>(null);

    useEffect(() => {
        setCaseStudy(null);
        if (app == null || id == null) return;
        getCaseStudy(app, id)
            .then((result) => {
                if (result.metaTitle) {
                    setTitle(result.metaTitle);
                }

                if (result.metaDescription) {
                    setDescription(result.metaDescription);
                }
        
                setCaseStudy(result);
            })
    }, [app, id, setTitle, setDescription]);

    if (!caseStudy) return null;

    return (
        <div className="CaseStudyPage">
            <div className="CaseStudyPage__Content">
                {caseStudy.logo && <img className="CaseStudyPage__Logo" src={caseStudy.logo} alt={`${caseStudy.title}-logo`} />}
                <div className="CaseStudyPage__Title">{caseStudy.title}</div>
                <div className="CaseStudyPage__Description">{caseStudy.description}</div>
                <div className="CaseStudyPage__HeroImageWrapper">
                    {caseStudy.heroImageLarge && <img className="CaseStudyPage__HeroImageLarge" src={caseStudy.heroImageLarge} alt="Case Study" />}
                    {caseStudy.heroImage && <img className="CaseStudyPage__HeroImage" src={caseStudy.heroImage} alt="Case Study" />}
                </div>
                <div className="CaseStudyPage__CopyWrapper">
                    <div className="CaseStudyPage__Copy">
                        {caseStudy.copy.map((entry) => <CaseStudyCopy key={getCopyKey(entry)} copy={entry} />)}
                        <div className="CaseStudyPage__ContactTitle">Want to work with us?</div>
                        <div className="CaseStudyPage__ContactSubtitle">Fill out the form & we'll be in touch!</div>
                        <ContactForm variant="small" />
                    </div>
                    <div className="CaseStudyPage__Cards">
                        {caseStudy.cards.map(card => <CaseStudyCard key={card} caseStudyId={card} />)}
                    </div>
                </div>
            </div>
        </div>
    )
};