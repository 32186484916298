import {useContext, useEffect } from 'react';
import { MetaContext } from '../../contexts/MetaContext';
import { ContactForm } from '../../components/contactForm/ContactForm';

import './contact.css';

export const Contact = () => {
    const { reset } = useContext(MetaContext);

    useEffect(() => {
        reset();
    }, [reset]);

    return (
        <div className="Contact">
            <div className="Contact__Content">
                <div className="Contact__Title">Contact Us</div>
                <div className="Contact__Subtitle">Want to work with us? Fill out the form and we'll be in touch!</div>
                <ContactForm />
            </div>
        </div>
    );
};

