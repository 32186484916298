import { Animation } from "../../../components/animation/Animation";
import { CardContent } from "../../../components/cardContent/CardContent";
import { CardImage } from "../../../components/cardImage/CardImage";
import { TransitionCard } from "../../../components/tranisitonCard/TransitionCard";

import mobileFriendly from '../../../assets/images/mobile-friendly.png';
import technicalAssistance from '../../../assets/images/technical-assistance.png';
import expenses from '../../../assets/images/expenses.png';
import freedomOfApproach from '../animations/freedom_of_approach.json';
import minimalAdSmall from '../../../assets/images/minimal-ad-small.png';
import minimalAdLarge from '../../../assets/images/minimal-ad-large.png';
import migrationSupport from '../../../assets/images/migration-support.png';
import seo from '../animations/seo.json';
import customUrls from '../animations/custom-urls.json';
import { Location } from "../../../types/Location";

import './cardGrid.css';

export const CardGrid = () => (
    <div className="CardGrid">
        <div className="CardGrid__Cards">
            <div className="CardGrid__Card CardGrid__Card--half">
                <TransitionCard lightLocation={Location.BottomLeft}>
                    <CardContent
                        title="Mobile Friendly"
                        message="Dedicated mobile support to ensure your wiki runs perfectly on mobile devices, interruption free"
                    >
                        <CardImage src={mobileFriendly} alt="Mobile Friendly" />
                    </CardContent>
                </TransitionCard>
            </div>
            <div className="CardGrid__Card CardGrid__Card--half">
                <TransitionCard lightLocation={Location.TopLeft}>
                    <CardContent
                        title="Technical Assistance and support"
                        message="We have a team of Mediawiki specialists to help you onboard and fix any issues"
                    >
                        <CardImage src={technicalAssistance} alt="Technical Assistance" />
                    </CardContent>
                </TransitionCard>
            </div>
            <div className="CardGrid__Card CardGrid__Card--half">
                <TransitionCard lightLocation={Location.TopRight}>
                    <CardContent
                        title="All Expenses covered"
                        message="We will cover all costs from hosting the servers, to providing continuous optimisations to ensure maximum speed and user experience."
                    >
                        <CardImage src={expenses} alt="Expenses Covered" />
                    </CardContent>
                </TransitionCard>
            </div>
            <div className="CardGrid__Card CardGrid__Card--half">
                <TransitionCard lightLocation={Location.BottomRight}>
                    <CardContent
                        title="Freedom of approach"
                        message="Everything is in full control of your admins, we want you to have the exact wiki the community wants"
                    >
                        <Animation data={freedomOfApproach} />
                    </CardContent>
                </TransitionCard>
            </div>
            <div className="CardGrid__Card CardGrid__Card--full">
                <TransitionCard lightLocation={Location.Bottom}>
                    <CardContent
                        title="Minimal Ad Experience"
                        message="Ads are created with users in mind, this means no more intrusive ads, and no poor user experience"
                    >
                        <div className="CardGrid__Image--small">
                            <CardImage src={minimalAdSmall} alt="Minimal Ads" />
                        </div>
                        <div className="CardGrid__Image--large">
                            <CardImage src={minimalAdLarge} alt="Minimal Ads" />
                        </div>
                    </CardContent>
                </TransitionCard>
            </div>
            <div className="CardGrid__Card CardGrid__Card--half">
                <TransitionCard lightLocation={Location.BottomRight}>
                    <CardContent
                        title="Migration Support"
                        message="Our team is here to help you get set-up, and get content moving so you can start creating and editing"
                    >
                        <CardImage src={migrationSupport} alt="Migration Support" />
                    </CardContent>
                </TransitionCard>
            </div>
            <div className="CardGrid__Card CardGrid__Card--half">
                <TransitionCard lightLocation={Location.TopLeft}>
                    <CardContent
                        title="SEO specialists"
                        message="We have a team fully equipped with all the tools and knowledge to help you grow the site"
                    >
                        <Animation data={seo} />
                    </CardContent>
                </TransitionCard>
            </div>
            <div className="CardGrid__Card CardGrid__Card--full">
                <TransitionCard lightLocation={Location.Top}>
                    <CardContent
                        title="Custom URLs & branding"
                        message="You’re not attached to any company or subdomain, and sit with independence"
                    >
                        <Animation data={customUrls} />
                    </CardContent>
                </TransitionCard>
            </div>
        </div>
    </div>
)