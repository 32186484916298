import { createContext, useCallback } from 'react'
import { getDescription } from '../helpers/getDescription';

type Props = {children: React.ReactNode}

export const MetaContext = createContext<
    {
        setTitle: (value: string) => void
        setDescription: (value: string) => void
        reset: () => void
    }
>({
  setTitle: (value: string) => null,
  setDescription: (value: string) => null,
  reset: () => null
});

const initialTitle = document.title;
const initialDescription = getDescription();

export const MetaProvider = ({ children }: Props) => {
  const setTitle = useCallback((title: string) => {
    document.title = title;
  }, []);

  const setDescription = useCallback((description: string) => {
    document.querySelector('meta[name="description"]')?.setAttribute("content", description);
  }, []);

  const reset = useCallback(() => {
    document.title = initialTitle;
    document.querySelector('meta[name="description"]')?.setAttribute("content", initialDescription);
  }, []);

  return (
    <MetaContext.Provider value={{ setTitle, setDescription, reset }}>
      {children}
    </MetaContext.Provider>
  )
}