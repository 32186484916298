import './loadingOverlay.css';

export const LoadingOverlay = () => {
    return (
        <div className="LoadingOverlay">
            <div className="LoadingOverlay__Spinner">
                <div />
                <div />
                <div />
                <div />
            </div>
        </div>
    )
};
