import { CaseStudy } from "../types/CaseStudy";
import { parseFileField } from "./parseFileField";


export const parseCaseStudy = async (caseStudy: CaseStudy): Promise<CaseStudy> => {
    return {
        ...caseStudy,
        logo: await parseFileField(caseStudy.logo),
        heroImage: await parseFileField(caseStudy.heroImage),
        heroImageLarge: await parseFileField(caseStudy.heroImageLarge),
        copy: await Promise.all(caseStudy.copy.map(async copyEntry => {
            if (copyEntry.type !== 'image') return copyEntry;
            return {
                ...copyEntry,
                src: await parseFileField(copyEntry.src) || '',
            }
        })),
    }
}