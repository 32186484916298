import { getDownloadURL, getStorage, ref } from 'firebase/storage';

export const storagePathToUrl = async (documentLink: string) => {
  const storage = getStorage();
  const [, path] = documentLink.split(/appspot.com\//);
  const url = await getDownloadURL(ref(storage, path));

  return url;
};

export default storagePathToUrl;
