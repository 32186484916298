import { RouterProvider, createBrowserRouter } from 'react-router-dom';

import { LandingPage } from './containers/landingPage/LandingPage';

import { Contact } from './containers/contact/Contact';
import { CaseStudyPage } from './containers/caseStudyPage/CaseStudyPage';
import { FirebaseProvider } from './contexts/FirebaseContext';
import { Page } from './components/page/Page';
import { MetaProvider } from './contexts/MetaContext';
import { PolicyDisplay } from './containers/policyDisplay/PolicyDisplay';

const App = () => {
  const router = createBrowserRouter([
    {
      path: "/",
      element: (
        <Page>
          <LandingPage />
        </Page>
      ),
    },
    {
      path: '/contact',
      element: (
        <Page>
          <Contact />
        </Page>
      ),
    },
    {
      path: '/case-study/:id',
      element: (
        <Page>
          <CaseStudyPage />
        </Page>
      ),
    },
    {
      path: '/cookies-policy',
      element: (
        <Page>
          <PolicyDisplay policyName='terms' />
        </Page>
      ),
    },
    {
      path: '/privacy-policy',
      element: (
        <Page>
          <PolicyDisplay policyName='privacy' />
        </Page>
      ),
    },
  ]);

  return (
    <FirebaseProvider>
      <MetaProvider>
        <RouterProvider router={router} />
      </MetaProvider>
    </FirebaseProvider>

  );
}

export default App;
