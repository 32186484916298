import { CaseStudy } from '../../../types/CaseStudy';

import './caseStudiesCardContent.css';

interface Props {
    caseStudy: CaseStudy
}

export const CaseStudiesCardContent = ({ caseStudy }: Props) => (
    <div className="CaseStudiesCardContent">
        <div className="CaseStudiesCardContent__Details">
            {caseStudy.logo && <img className="CaseStudiesCardContent__Logo" src={caseStudy.logo} alt="Case Study Icon" />}
            <div className="CaseStudiesCardContent__Description">
                {caseStudy.description}
            </div>
        </div>
        <div className="CaseStudiesCardContent__HeroImageWrapper">
            {caseStudy.heroImageLarge && <img className="CaseStudiesCardContent__HeroImageLarge" src={caseStudy.heroImageLarge} alt="Case Study" />}
            {caseStudy.heroImage && <img className="CaseStudiesCardContent__HeroImage" src={caseStudy.heroImage} alt="Case Study" />}
        </div>
    </div>
);
