import './cardImage.css';

interface Props {
    src: string
    alt: string
}

export const CardImage = ({ src, alt }: Props) => (
    <img className="CardImage" src={src} alt={alt}/>
);
