import { FirebaseApp } from "firebase/app";
import { doc, getDoc, getFirestore } from "firebase/firestore";
import { CaseStudy } from "../types/CaseStudy";
import { parseCaseStudy } from "./parseCaseStudy";

export const getCaseStudy = async (app: FirebaseApp, id: string): Promise<CaseStudy> => {
    const db = getFirestore(app);
    const docRef = doc(db, 'case-studies', id)
    const snapshot = await getDoc(docRef);

    if (!snapshot.exists()) {
        throw new Error('Case Study not found');
    }

    const result: CaseStudy = {
        id: snapshot.id,
        ...snapshot.data() as Omit<CaseStudy, 'id'>,
    };

    return parseCaseStudy(result);
};
