import { Link } from 'react-router-dom';
import './footer.css';

export const Footer = () => (
    <div className="Footer">
        <div className="Footer__Links">
            <Link className="Footer__Link" to="/cookies-policy">Cookies Policys</Link>
            <Link className="Footer__Link" to="/privacy-policy">Privacy Policy</Link>
            <Link className="Footer__Link" to="/contact">Contact Us</Link>
        </div>
        <div className="Footer__Copyright">
            &copy; 2024 Fork Your Wiki. All Rights Reserved
        </div>
    </div>
)