import { Link } from "react-router-dom";
import { LogoHorizontalColor } from "../../icons/LogoHorizontalColor"

import './navBar.css';

export const NavBar = () => {
    return (
        <div className="NavBar">
            <div className="NavBar__Content">
                <Link className="NavBar__Logo" to="/">
                    <LogoHorizontalColor />
                </Link>
                <Link className="NavBar__ContactButton" to="/contact">Contact Us</Link>
            </div>
        </div>
    )
};