import { ReactNode } from "react";
import { NavBar } from "../navBar/NavBar";
import { Footer } from "../footer/Footer";

import './page.css';

interface Props {
    children: ReactNode
}

export const Page = ({ children }: Props) => {
    return (
        <div className="Page">
            <NavBar />
            <div className="Page__Content">
                {children}
            </div>
            <Footer />
        </div>
    )
}