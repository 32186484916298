import { ChangeEvent, InputHTMLAttributes, useCallback } from "react";

import './textField.css';

interface Props {
    label: string
    placeholder: string
    value: string
    onChange: (value: string) => void
    type?: InputHTMLAttributes<HTMLInputElement>['type']
    variant?: 'small' | 'regular'
}

export const TextField = ({ label, placeholder, value, onChange, type = 'text', variant = 'regular' }: Props) => {
    const handleTextChange = useCallback((event: ChangeEvent<HTMLInputElement> | ChangeEvent<HTMLTextAreaElement>) => {
        onChange(event.target.value || '');
    }, [onChange]);

    return (
        <div className={`TextField TextField--${variant}`}>
            <div className="TextField__Label">{label}</div>
            {type === 'textarea' ? (
                <textarea
                    className="TextField__Input TextField__Input--textarea"
                    value={value}
                    placeholder={placeholder}
                    onChange={handleTextChange}
                    rows={9}
                />
            ) : (
                <input
                    className="TextField__Input"
                    type={type}
                    placeholder={placeholder}
                    onChange={handleTextChange}
                    value={value}
                />
            )}
        </div>
    )
};
