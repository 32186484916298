import { FirebaseApp } from "firebase/app";
import { collection, getDocs, getFirestore } from "firebase/firestore";
import { CaseStudy } from "../types/CaseStudy";
import { parseCaseStudy } from "./parseCaseStudy";

export const getCaseStudies = async (app: FirebaseApp): Promise<CaseStudy[]> => {
    const db = getFirestore(app);
    const querySnapshot = await getDocs(collection(db, 'case-studies'));

    const result: CaseStudy[] = [];
    querySnapshot.forEach((doc) => {
        const game: CaseStudy = {
            id: doc.id,
            ...doc.data() as Omit<CaseStudy, 'id'>,
        };
        result.push(game);
    });

    const parsedResults: CaseStudy[] = await Promise.all(result.map(async (entry: CaseStudy) => parseCaseStudy(entry)));

    return parsedResults.sort((a, b) => (a.position || 100) - (b.position || 100) ? -1 : 1);
};
