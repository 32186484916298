import { ReactNode } from "react"

import heroBackground from '../../assets/images/hero-background.png';

import './hero.css';

interface Props {
    children: ReactNode
    img: string
    alt: string
}

export const Hero = ({ children, img, alt }: Props) => (
    <div className="Hero">
        <img className="Hero__BackgroundImage" src={heroBackground} alt="Hero Background"/>
        <div className="Hero__ContentBackground">
            <div className="Hero__Content">
                <div className="Hero__Text">
                    {children}
                </div>
                <div className="Hero__ImageWrapper">
                    <img className="Hero__Image" src={img} alt={alt} />
                    <div className="Hero__ImageFade" />
                </div>
            </div>
        </div>
    </div>
)