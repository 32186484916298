import { useContext, useEffect, useState } from "react";
import { CaseStudy } from "../../../types/CaseStudy";
import { getCaseStudies } from "../../../helpers/getCaseStudies";
import { TransitionCard } from "../../../components/tranisitonCard/TransitionCard";
import { CaseStudiesCardContent } from "./CaseStudiesCardContent";
import { getGridSize } from "../helpers/getGridSize";
import { FirebaseContext } from "../../../contexts/FirebaseContext";
import { Location } from "../../../types/Location";

import './caseStudies.css';
import { Link } from "react-router-dom";

export const CaseStudies = () => {
    const [caseStudies, setCaseStudies] = useState<CaseStudy[]>([]);
    const { app } = useContext(FirebaseContext);

    useEffect(() => {
        if (app == null) return;
        getCaseStudies(app)
            .then((result) => {
                setCaseStudies(result);
            });
    }, [app]);

    if (caseStudies.length === 0) return null;

    const gridSize = getGridSize(caseStudies);

    return (
        <div className={`CaseStudies CaseStudies--${gridSize}`}>
            {caseStudies.map((caseStudy) => (
                <Link to={`/case-study/${caseStudy.id}`}>
                    <TransitionCard key={caseStudy.id} lightLocation={Location.Bottom}>
                        <CaseStudiesCardContent caseStudy={caseStudy} />
                    </TransitionCard>
                </Link>
            ))}
        </div>
    )
};
