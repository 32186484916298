import { FirebaseApp } from "firebase/app";
import { doc, getDoc, getFirestore } from "firebase/firestore";
import { PolicyEntry } from "../../../types/PolicyEntry";
import { Policy } from "../../../types/Policy";

export const getPolicy = async (app: FirebaseApp, key: string): Promise<PolicyEntry[]> => {
    const db = getFirestore(app);
    const docRef = doc(db, 'policies', key)
    const snapshot = await getDoc(docRef);

    if (!snapshot.exists()) {
        throw new Error('Case Study not found');
    }

    const data = snapshot.data() as Policy;

    return data.entries;
};
