import { Copy } from "../../../types/Copy";

export const getCopyKey = (copy: Copy) => {
    switch (copy.type) {
        case 'image':
            return copy.src;
        case 'text':
            return copy.text;
        default:
            throw new Error('Unknown copy type');
    }
}