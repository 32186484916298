import { ReactNode, useEffect, useMemo, useRef, useState } from 'react';

import { isVisible } from '../../helpers/isVisible';
import { Location } from '../../types/Location';

import './transitionCard.css';

interface Props {
    lightLocation?: Location | null
    children: ReactNode
}

export const TransitionCard = ({ lightLocation = null, children }: Props) => {
    const ref = useRef<HTMLDivElement | null>(null);
    const [opacity, setOpacity] = useState(0);

    useEffect(() => {
        const onScroll = () => {
            if (isVisible(ref.current)) {
                setOpacity(1);
            } else {
                setOpacity(0);
            }
        };
        window.addEventListener('scroll', onScroll)

        return () => {
            window.removeEventListener('scroll', onScroll);
        }
    }, [ref]);

    const fadeClass = useMemo(() => {
        let baseClass = 'TransitionCard__Fade';
        switch (lightLocation) {
            case Location.TopRight:
                return baseClass + ' TransitionCard__Fade--topRight';
            case Location.BottomLeft:
                return baseClass + ' TransitionCard__Fade--bottomLeft';
            case Location.BottomRight:
                return baseClass + ' TransitionCard__Fade--bottomRight';
            case Location.Top:
                return baseClass + ' TransitionCard__Fade--top';
            case Location.Right:
                return baseClass + ' TransitionCard__Fade--right';
            case Location.Bottom:
                return baseClass + ' TransitionCard__Fade--bottom';
            case Location.Left:
                return baseClass + ' TransitionCard__Fade--left';
            case Location.TopLeft:
                return baseClass + ' TransitionCard__Fade--topLeft';
            default:
                return baseClass;
        }
    }, [lightLocation]);

    return (
        <div ref={ref} className="TransitionCard">
            <div className="TransitionCard__Content" style={{ opacity }}>
                {children}
            </div>
            <div className={fadeClass} />
        </div>
    );
}
